import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // Define static value to fetch the URL from HTML data attributes.
  static values = { 
      val: String,
      fieldName: String,
      formId: String,
  };
  
  // Triggered when the Stimulus controller is connected to the DOM.
  connect() {
    const initial_status = this.valValue; 
    //this.setValue;
  }

  // Triggered when the Stimulus controller is removed from the DOM.
  disconnect() {
    this.valValue = this.initial_status;
  }

  /**setValue() {
    // set value of hidden input to static val value
    this.hidden_params_target.value = this.valValue;
  }*/
  
  switchValue(event) {
    // stop immediate propagation & prevent default action (form submit)
    event.stopPropagation();
    event.preventDefault();
    const hidden_params_target = document.querySelector(`input[type='hidden'][name='${this.fieldNameValue}']`);
    const form_target = document.querySelector(`#${this.formIdValue}`)
    
    // switch hidden input value to opposite of static val value
    hidden_params_target.value = this.valValue;
    form_target.submit();
  }
}

