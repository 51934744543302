import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["indicator"]
  static classes = ['active','inactive','hidden']
  
  toggle(event) {
    //console.log(`${event.target.id} : ${event.target.dataset.focus}`)
    if(this.indicatorTarget.classList.contains(this.inactiveClass)) {
      this.indicatorTarget.classList.replace(this.inactiveClass, this.activeClass);
    } else {
      this.indicatorTarget.classList.replace(this.activeClass, this.inactiveClass);
    }
  } 


}